import React, { useEffect, useState, useRef } from 'react';
import {
  HeroBannerComponent,
  HeroBannerCTAButton,
  HeroBannerDescription,
  HeroBannerHeading,
  HeroBannerImage,
  HeroBannerInteractiveBody,
  HeroBannerInteractivePosition,
  HeroBannerStoreImage,
  ViewMoreSpan,
} from './HeroBanner3.styles';
import { Button as HeroBannerButton } from '../../AtomicComponents';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import { deviceWidth } from '@/utils/deviceWidth';
import { getHeroBannerHeightCalculation } from 'src/redux/actions';
import { renderStoreName } from '@/utils/renderStoreName';
import { bannerImageClick } from '@/utils/bannerImageClick';
import { heroBannerCTASystem } from '@/utils/heroBannerCTASystem';
import { Slider } from '@/components/ReactSlickCarousel';
import { useShopClickFunc } from '../../../../hooks/useShopClickFunc';
import useBannerImages from '../hooks/useBannerImages';
import BannerImageSliderNextPrevButtons from '../common/BannerImageSliderNextPrevButtons';
import { sliderSettings } from '../utils';
import { useSSRSelector } from '@/redux/ssrStore';
import Image from 'next/image';

const HeroBanner3 = (props) => {
  const [shopBtnClick] = useShopClickFunc();
  const router = useRouter();
  const sliderRef = useRef();

  const dispatch = useDispatch();

  const { heroBannerHeight } = useSelector(
    (state) => state.commonReducer.heightCalculation
  );

  const { store_info, theme } = useSSRSelector((state) => state.storeReducer.store);

  const [bannerImages] = useBannerImages();

  useEffect(() => {
    dispatch(getHeroBannerHeightCalculation());
  }, []);

  const [state, setState] = useState({
    isHeightFetched: false,
    heightFetched: 0,
  });

  useEffect(() => {
    if (!state.heightFetched) {
      setState((state) => ({
        ...state,
        heightFetched: heroBannerHeight,
        isHeightFetched: true,
      }));
    }
  }, [heroBannerHeight]);

  return theme ? (
    <div className={`heroBannerWrapper ${!bannerImages?.length ? 'placeholder' : ''}`}>
      {bannerImages?.length > 1 && (
        <BannerImageSliderNextPrevButtons sliderRef={sliderRef} />
      )}
      <Slider ref={(slider) => (sliderRef.current = slider)} {...sliderSettings}>
        {bannerImages?.map((img, idx) => (
          <HeroBannerComponent
            bannerFixedRatio={theme?.banner_fixed_ratio}
            heightCalculation={state.heightFetched}
            className={img?.image_href !== '' ? 'pointer' : ''}
            key={idx}
            onClick={() => bannerImageClick(theme, img)}
          >
            <HeroBannerImage>
              {img?.image_url && (
                <Image
                  layout="fill"
                  className="next-banner-image"
                  objectFit="cover"
                  objectPosition="center center"
                  alt={`Main Banner Image ${idx + 1}`}
                  src={img.image_url}
                  priority={idx == 0}
                />
              )}
            </HeroBannerImage>
            {idx === 0 &&
              (renderStoreName(deviceWidth, props?.config)?.logo ||
                renderStoreName(deviceWidth, props?.config)?.storeName) && (
                <HeroBannerInteractivePosition>
                  <HeroBannerInteractiveBody fontColor="#000000">
                    {renderStoreName(deviceWidth, props?.config)?.logo &&
                      store_info?.logo_image && (
                        <HeroBannerStoreImage>
                          <Image
                            src={store_info?.logo_image}
                            className="next-store-image"
                            alt="store logo"
                            height="100%"
                            width="100%"
                            objectFit="cover"
                            objectPosition="center center"
                          />
                        </HeroBannerStoreImage>
                      )}
                    {renderStoreName(deviceWidth, props?.config)?.storeName && (
                      <HeroBannerHeading>{store_info?.name}</HeroBannerHeading>
                    )}
                    {store_info &&
                    store_info?.description &&
                    store_info?.description.length > 164 ? (
                      <HeroBannerDescription>
                        {store_info?.description.substr(0, 164)}...
                        <ViewMoreSpan
                          onClick={() => {
                            router.push(getRoute(PATH.ABOUT_US, store_info?.domain));
                          }}
                        >
                          View More
                        </ViewMoreSpan>
                      </HeroBannerDescription>
                    ) : (
                      <HeroBannerDescription>
                        {store_info?.description}
                      </HeroBannerDescription>
                    )}
                    {heroBannerCTASystem(theme.theme_class) !== '' &&
                      theme?.cta_config?.banner_cta && (
                        <HeroBannerCTAButton>
                          <HeroBannerButton
                            backgroundColor="#000000"
                            label={theme?.cta_config?.banner_cta}
                            roundness={theme?.roundness}
                            borderColor="#000000"
                            fontColor="#ffffff"
                            px="10px"
                            py="10px"
                            onClick={shopBtnClick}
                          />
                        </HeroBannerCTAButton>
                      )}
                  </HeroBannerInteractiveBody>
                </HeroBannerInteractivePosition>
              )}
          </HeroBannerComponent>
        ))}
      </Slider>
    </div>
  ) : (
    <></>
  );
};

export default HeroBanner3;
